import React from "react"
import { Link } from "gatsby"

const Finalctablock = () => {
  return (
    <div className="container">
      <section className="lp-action-section">
        <h2>Start growing with InsideHero today</h2>

        <div className="lp-action-section-btns">
          <Link to="/demo">
            <button className="btn btn-main btn-hero lp-action-btn">
              BOOK DEMO
            </button>
          </Link>
          {/*<a href=""><button className="btn btn-sol lp-action-btn">LEARN MORE</button></a> */}
        </div>
      </section>
    </div>
  )
}

export default Finalctablock

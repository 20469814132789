import React from "react"
import Layout from "../components/Layout"
import aboumainsectionimg from "../images/about-main-sectionimg.png"

import Finalctablock from "../components/Finalctablock"

const about = () => {
  return (
    <Layout>
      <div className="container stripeanchor">
        <div className="about-stripe-left"></div>
        <section className="about-hero-section">
          <h1>Making marketing easy for local businesses</h1>
          <p>
            InsideHero is building tools that help bring in more customers for
            local businesses
          </p>
        </section>
        <div className="about-stripe-right"></div>
      </div>

      <div className="about-grad">
        <div className="container">
          <section className="about-main-section">
            <div className="about-main-section-txt">
              <h2>It all started with a tile shop.</h2>

              <p>
                Some time ago, our founder got a call from a title shop owner
                who was struggling to compete online. Even though he had tons of
                happy customers, mostly angry customers were leaving reviews.
                His reviews didn’t reflect his great service, let alone the
                years of happy customers he’s had.
              </p>

              <p>
                Not only this, people would have to call his shop to get a quote
                and do business in general which was inconvenient compared to
                his online competition. So we set out to help local businesses
                collect reviews, and message their customers. We’re since
                building out experiences that are convenient for local
                businesses and their customers.
              </p>
            </div>

            <div className="about-main-section-img">
              <img src={aboumainsectionimg} alt="" />
            </div>
          </section>
        </div>
      </div>

      {/* <div className="container">
        <section className="team-section">
          <h3>We're just getting started</h3>

          <div className="team-section-block">
            <div className="team-section-block-member member-one">
              <img src={teamsectionimgone} alt="" />
              <h4></h4>
              <p>//</p>
            </div>

            <div className="team-section-block-member member-two">
              <div className="team-section-block-img">
                <img src={teamsectionimgone} alt="" />
              </div>
              <h4>//</h4>
              <p>//</p>
            </div>
          </div>
        </section>
      </div> */}

      <Finalctablock />
    </Layout>
  )
}

export default about
